import React from "react";

export default function BioText() {
  return (
    <div className="bio lineStyle">
      <p>
        <em>Lisa Botalico</em> is currently Assistant Director/Principal
        Dancer/Choreographer of <em>Alborada Spanish Dance Theatre</em>. Lisa
        toured the United States as Artistic Director and Principal dancer of{" "}
        <em>La Compañía Folklorica Latina</em> for the National Theatre of the
        Performing Arts. She was featured many times on NJN’s{" "}
        <em>Images/Imagenes</em>, including the Emmy Award winning documentary,{" "}
        <em>The Spanish Guitar</em>. New Jersey performances include: NJPAC,
        Morristown Community Theatre, Crossroads Theatre, George Street
        Playhouse and New Brunswick PAC.{" "}
      </p>
      <p>
        A much sought after teacher/choreographer, Lisa founded the ongoing
        Children and Adult Flamenco Dance Program at the{" "}
        <em>Arts Council of Princeton</em> in 1999 and also teaches for
        Princeton Dance and Theatre Studio’s conservatory program. She has
        taught for the recreation departments of Rutgers and Princeton
        Universities and Union County College’s Continuing Education Program and
        as guest presenter for Rider University’s Dance Program. Lisa’s arts in
        education residences include: Princeton Regional Schools, Princeton Day
        School, Stuart Country Day School; Lawrenceville School and Ewing Public
        Schools.{" "}
      </p>
      <p>
        Lisa’s photo was included in the retrospective,{" "}
        <em>100 Years of Flamenco in NYC</em> at the New York Public Library for
        the Performing Arts Lincoln Center and she was the{" "}
        <em>Arts Council of Princeton’s 2014 Artist in Residence</em>. Lisa has
        collaborated with the <em>Roxey Ballet</em> in their productions of{" "}
        <em>Carmen</em> and <em>Latin Creations</em>. In 2019 Lisa was nominated
        for a Perry Award for Best Choreography by the New Jersey Association of
        Community Theaters for her choreography in the North East American
        premier of <em>Zorro, the Musical</em>, music by the Gipsy Kings,
        produced by <em>PinnWorth Productions</em> at the Kelsey Theater. In
        June, 2024, Lisa Botalico will have a studio named in her honor
        commemorating her twenty five years of teaching and performing at The
        Arts Council of Princeton.
      </p>
    </div>
  );
}
